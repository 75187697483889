.experimental-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
  padding: 0px;
  z-index: 1000; /* Ensure it stays on top */
  font-size: 10px;
}
