.latest-works-container {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}

.latest-works-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.slick-slider {
  position: relative;
}

.latest-works-item {
  padding: 0 10px; /* Space between items */
  text-align: center;
}

.latest-works-item img {
  width: 100%;
  max-width: 160px; /* Set a uniform size */
  height: 230px;
  object-fit: cover;
  border-radius: 10px;
}
