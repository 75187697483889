.steps-container {
  text-align: center;
  padding: 20px;
}

.steps-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.steps-flow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 20px; /* Add space between steps and arrows */
}

.step {
  background-color: #f7f2e9;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
  flex: 1;
  max-width: 200px;
  border: 2px solid #f0c040;
  text-align: center;
}

.step h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.step p {
  font-size: 14px;
  color: #333;
}

.arrow {
  font-size: 24px;
  margin: 0 20px;
  flex-shrink: 0; /* Prevent arrows from shrinking */
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .steps-flow {
    flex-direction: column; /* Stack steps vertically */
    align-items: center; /* Center align the steps and arrows */
  }

  .step {
    max-width: 90%; /* Allow steps to fill most of the screen width */
    margin: 10px 0; /* Adjust margins for vertical layout */
  }

  .arrow {
    display: block; /* Ensure arrows are visible */
    margin: 10px 0; /* Add margin around arrows */
    transform: rotate(90deg); /* Rotate arrows for vertical layout */
  }

  .steps-title {
    font-size: 20px; /* Slightly smaller title for mobile */
  }
}
