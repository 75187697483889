.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 2rem;
  background-color: #f6f2ea;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
  color: #ffa500;
}

.slick-slide > div {
  padding: 0 10px; /* Adds 10px space on left and right between cards */
}

.product-grid-container {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.slick-arrow.slick-prev {
  margin-top: -40px;
}

.slick-arrow.slick-prev:before,
.slick-arrow.slick-next:before {
  content: none !important; /* Force removal of content */
}
