.cart-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.cart-items-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item-row {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.cart-item-image {
  width: 150px;
  height: auto;
  border-radius: 4px;
}

.cart-item-details {
  flex: 1;
  padding: 0 20px;
  text-align: left;
}

.cart-item-quantity {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart-item-quantity button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.cart-item-quantity button:disabled {
  background-color: #ccc;
}

.cart-summary {
  margin-top: 30px;
  text-align: center;
}

.clear-cart-btn,
.finalize-order-btn {
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #f04e23;
  color: white;
  font-size: 16px;
  transition: background-color 0.2s;
}

.clear-cart-btn:hover,
.finalize-order-btn:hover {
  background-color: #d2431c;
}

.finalize-order-btn {
  background-color: #4caf50;
}

.finalize-order-btn:hover {
  background-color: #45a049;
}

/* Popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease;
  z-index: 2;
}

/* Popup content */
.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: scaleIn 0.3s ease;
}

.close-popup-btn {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #f04e23;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.close-popup-btn:hover {
  background-color: #d2431c;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
