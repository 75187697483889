.roomlayout-container {
  display: flex;
  width: 100%;
  background-color: white;
}

.roomlayout-container canvas {
  max-height: 80vh;
  max-width: 80vw;
}

.roomlayout-left-column {
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 1rem 20px;
  background-color: white;
}

.roomlayout-button {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #ffad00;
  border: none;
  color: white;
  border-radius: 5px;
  transition: background-color 0.2s;
  width: 100%;
}

.roomlayout-button:hover {
  background-color: #e69a00;
}

.roomlayout-button-disabled {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  background-color: #dcdcdc;
  border: none;
  color: white;
  border-radius: 5px;
}

.roomlayout-right-column {
  width: 70%;
  padding: 1rem 1rem 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center; /* Centers the content horizontally */
  align-items: start; /* Aligns content at the top */
}

.konvajs-content {
  max-width: 100%; /* Ensures it doesn't overflow */
  display: block;
  left: 5vw;
}

.roomlayout-right-column img {
  max-height: 80vh;
  width: 60vh;
}

.roomlayout-size-example {
  margin-bottom: 20px;
}

.roomlayout-room-background {
  object-fit: cover;
}

/* Inline photo preview between buttons */
.roomlayout-photo-preview-inline {
  margin: 10px 0;
  text-align: center;
}

.roomlayout-uploaded-photo-inline {
  max-width: 100%;
  width: 100%; /* Same width as buttons */
  object-fit: cover;
}

.roomlayout-dropdown {
  position: relative;
}

.roomlayout-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.roomlayout-dropdown:hover .roomlayout-dropdown-content {
  display: block;
}

.roomlayout-dropdown-item {
  padding: 10px;
  background-color: white;
  border: none;
  text-align: left;
  width: 100%;
  cursor: pointer;
  color: black;
}

.roomlayout-dropdown-item:hover {
  background-color: #ffad00;
  color: white;
}

.roomlayout-hint {
  color: red;
  margin-top: 10px;
}
