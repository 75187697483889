.price-sizes-container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: auto auto;
  gap: 20px;
  padding: 20px;
}

.column1 {
}

.column1.row2 {
  padding-left: 50px;
}

.price-n-sizes-list {
  list-style-type: none;
  padding-left: 25px;
  margin: 0;
}

.price-n-sizes-list li {
  display: flex;
  align-items: center;
}

.price-n-sizes-list li::before {
  content: "-";
  display: inline-block;
  margin-right: 50px;
}

.price-n-sizes-list li span {
  font-size: 16px;
  color: #333;
}

.price-n-sizes-list li:hover span {
  color: #e69a00;
}

.row1 h3 {
  margin: 0;
  font-size: 24px;
}

.size-button {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #ffad00;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.size-button:hover {
  background-color: #e69a00;
}

.row2 p {
  margin: 0 0 10px 0;
  line-height: 1.5;
}

/* Center the buttons */
.column2.row1 {
  display: flex;
  justify-content: center;
  /* Horizontally center the buttons */
  align-items: center;
  /* Vertically center the buttons */
}

/* Center the image */
.column2.row2 {
  display: flex;
  justify-content: center;
  /* Horizontally center the image */
  align-items: center;
  /* Vertically center the image */
}

.price-photo-preview {
  max-height: 800px;
  /* Set maximum height */
  width: auto;
  height: 50vh;
  max-width: 100%;
  /* Ensure the image scales within its container */
  border-radius: 10px;
}
