.info-hint-container {
  position: relative;
  display: inline-block;
}

.info-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffad00;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  margin-left: -8px;
  margin-right: 10px;
}

h4 .info-circle {
  margin-left: 5px;
  margin-right: 0px;
}

.hint-box {
  position: absolute;
  top: -40px;
  right: -70px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1;
  transform: translateX(-50%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 200px;
}

.hint-box::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}
