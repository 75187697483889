.personal-cabinet {
  display: flex;
}

.menu {
  width: 20%;
  background-color: white;
  padding: 20px;
}

.menu ul {
  list-style-type: none;
  padding: 0;
}

.menu ul li {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

/* Highlight the active component */
.menu ul li.active {
  background-color: #ffad00;
  color: white;
}

/* Hover state for non-active items */
.menu ul li:hover {
  background-color: #f4bf4f;
}

.content {
  width: 80%;
  padding: 20px;
}
