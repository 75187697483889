.footer-info-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f7f7f7;
  user-select: none;
  flex-wrap: wrap;
}

.footer-logo-section {
  max-width: 700px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%; /* Full width on mobile */
}

.footer-logo {
  max-width: 150px;
  margin: 0 auto;
}

.footer-social-links img {
  width: 30px;
  margin: 0 10px;
}

.footer-links-section {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 50px;
  flex-wrap: wrap;
}

.footer-links-column {
  margin-right: 20px;
  text-align: left; /* Ensure alignment to the left */
}

.footer-links-column h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-links-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-links-column li {
  margin-bottom: 5px;
}

.footer-links-column a {
  text-decoration: none;
  color: #333;
}

.footer-links-column a:hover {
  text-decoration: underline;
}

.footer-paragraph {
  cursor: default;
}

/* Mobile Styles (Less than 600px) */
@media only screen and (max-width: 1360px) {
  .footer-logo-section {
    max-width: 400px;
  }
}

@media only screen and (max-width: 1060px) {
  .footer-logo-section {
    max-width: 200px;
  }
}

@media only screen and (max-width: 860px) {
  .footer-logo-section {
    max-width: 100%;
  }
}

/* Mobile Styles (Less than 600px) */
@media only screen and (max-width: 599px) {
  .footer-info-container {
    flex-direction: column; /* Stack sections vertically */
    padding: 10px;
  }

  .footer-links-section {
    flex-direction: column;
    padding-left: 0;
    align-items: center; /* Center the links for mobile */
  }

  .footer-links-column {
    margin-bottom: 20px;
    margin-right: 0;
    text-align: center; /* Center the text in mobile view */
  }

  .footer-social-links img {
    width: 25px; /* Adjust size of social icons on smaller screens */
    margin: 0 5px;
  }

  .footer-logo {
    max-width: 120px; /* Reduce logo size on smaller screens */
  }
}
