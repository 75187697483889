.art-styles-container {
  display: flex;
  height: 100vh;
}

.column {
  padding: 20px;
}

.styles-column {
  width: 20%;
  border-right: 1px solid #ccc;
}

.description-column {
  width: 20%;
  border-right: 1px solid #ccc;
}

.preview-column {
  width: 60%;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.styles-list {
  list-style-type: none;
  padding: 0;
}

.style-item {
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  color: black;
}

.style-item.active {
  background-color: #ffad00;
  color: white;
}

.scrollable-preview {
  overflow-x: auto; /* Enable horizontal scrolling */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.preview-container {
  position: relative;
  overflow: hidden;
  height: 500px;
  width: 100%;
}

.before-image,
.after-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: ew-resize;
  z-index: 2; /* Ensure it's above the images */
}

/* Add styles for the slider bar */
.slider-bar {
  position: absolute;
  top: 0;
  /*height: 100%;*/
  width: 11px; /* Make it visible */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 3px;
  background-color: #ffad00; /* Color for the slider */
  z-index: 3; /* Keep it on top */
  cursor: ew-resize; /* Show a horizontal drag cursor */
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.slider-tip {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: white;
  font-weight: bold;
  font-size: 12px;
  color: black;
}

.order-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffad00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.order-button:hover {
  background-color: #f9c24b;
}
