/* Container */
.admin-dashboard-container {
  display: flex;
  min-height: 100vh;
}

/* Sidebar */
.admin-dashboard-sidebar {
  width: 20%;
  background-color: white;
  color: black;
  padding: 20px;
  box-sizing: border-box;
}

.admin-dashboard-sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.admin-dashboard-menu {
  list-style-type: none;
  padding: 0;
}

.admin-dashboard-menu li {
  padding: 10px;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-bottom: 5px;
}

.admin-dashboard-menu li.active {
  background-color: #ffad00;
  color: black;
}

.admin-dashboard-menu li:hover {
  background-color: #f4bf4f;
  color: black;
}

/* Content */
.admin-dashboard-content {
  width: 80%;
  padding: 20px;
  box-sizing: border-box;
}

/* Headings */
.admin-dashboard-content h1 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.admin-order-card {
  border-radius: 10px;
  padding: 20px 25px 10px 25px;
  background: white;
  margin-bottom: 15px;
  box-shadow: 5px 5px 0.8em rgb(211, 201, 182);
}

.admin-order-card button {
  margin-right: 5px;
  height: 27px;
  color: black;
}

.admin-order-card input {
  margin-right: 5px;
  height: 20px;
  color: black;
}

.pagination-button.active {
  color: black;
}
