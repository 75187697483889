.price-calculator {
  display: flex;
  flex-direction: column;
}

.calculator-top {
  flex: 0 0 70%; /* Fixed height of 70% */
  display: flex;
}

.columns-container {
  display: flex;
  width: 100%;
  height: 100%; /* Full height of the top section */
}

.column {
  flex: 1;
  padding: 10px;
  border-right: 1px solid #ccc;
  overflow: visible; /* Change this to ensure the hint is not cut off */
  max-height: calc(70vh - 20px);
  position: relative; /* Added to allow absolute positioning of the hint */
}

.column-size {
  flex: 0.35; /* Adjust this value to control the size */
  padding: 10px;
  border-right: 1px solid #ccc;
  overflow: visible;
  max-height: calc(70vh - 20px);
  position: relative;
}

.column:last-child {
  border-right: none; /* Remove the border from the last column */
}

/* Ensure checkboxes and radios appear on new lines */
.checkbox-label,
.radio-label {
  display: block; /* Each item takes a new line */
  margin-bottom: 5px; /* Add some space between items */
}

/* Bottom section with two columns for upload and price */
.calculator-bottom {
  flex: 0 0 30%; /* Fixed height of 30% */
  display: flex;
  flex-direction: row; /* Make the bottom part two columns */
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
}

/* Left column for file upload and photo preview */
.upload-column {
  flex: 1; /* Takes half the space */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 20px;
}

.upload-area {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.upload-label {
  cursor: pointer;
  color: #007bff;
}

.photo-preview {
  width: 100%;
  text-align: center;
}

.photo-preview img {
  max-width: 100px;
  border-radius: 5px;
}

/* Right column for price, clear, and order buttons */
.price-column {
  flex: 1; /* Takes half the space */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
}

.price-section {
  text-align: center;
  margin-bottom: 20px;
}

.clear-button {
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.clear-button:hover {
  background-color: #d32f2f;
}

.order-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.order-button:hover {
  background-color: #0056b3;
}

.roomlayout-hint {
  color: red;
  font-size: 12px;
  text-align: center;
}

.radio-label .info-hint-container {
  padding-left: 12px;
}

.price-disclaimer {
  padding-top: 10px;
  font-size: 13px;
}

.checkbox-label .additional-info {
  padding-left: 13px;
}
