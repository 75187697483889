.contact-container {
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 20px;
  gap: 20px;
  align-items: center;
}

.contact-column1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-image {
  max-width: 70%;
  height: auto;
  max-height: 500px;
}

.contact-column2 {
  padding-right: 100px;
}

.contact-heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-info {
  list-style-type: none;
  padding: 0;
  font-size: 18px;
  line-height: 1.6;
  color: #000000;
}

.contact-hours h3 {
  font-size: 20px;
  margin: 20px 0 10px;
}

.contact-orange {
  color: #ffad00;
  font-weight: bold;
}

a img {
  width: 70px;
  border-radius: 10px;
}

.contact-phone-links {
  padding-left: 10px;
}

.contact-phone-links a {
  padding-right: 15px;
}

.contact-phone-links a img {
  width: 50px;
}

.map-container {
  grid-column: 1 / -1; /* Span the entire grid */
  width: 100%;
  height: 300px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}
