.suggestions {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  position: absolute;
  width: 100%;
  z-index: 10;
  font-size: 14px; /* Make the options smaller */
  max-width: 400px;
}

.suggestions li {
  padding: 8px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}
