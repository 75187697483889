.admin-orders-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.admin-orders-order-field,
.admin-orders-order-direction {
  padding: 8px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.admin-orders-pagination {
  text-align: center;
  margin-top: 20px;
}

.admin-orders-pagination-btn {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #ffad00;
  color: white;
  cursor: pointer;
}

.admin-orders-pagination-btn:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
