.size-chooser {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.left-column button {
  background-color: #ffc107;
  border: 2px solid transparent;
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
  text-align: center;
}

.left-column .selected {
  border: 2px solid #ff9800;
  background-color: #ff9800;
  color: white;
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 2px solid #ff9800;
  transition:
    width 0.3s,
    height 0.3s;
}

/* Dynamic container sizes */
.right-container.portrait {
  width: 200px;
  height: 400px;
}

.right-container.album {
  width: 400px;
  height: 200px;
}

.right-container.square {
  width: 300px;
  height: 300px;
}

.size-class {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.size-class h4 {
  margin-bottom: 5px;
}

.size-options {
  display: flex;
  gap: 10px;
}

.size-options button {
  padding: 10px;
  background-color: #ffc107;
  border: 2px solid transparent;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.size-options .selected {
  background-color: #ff9800;
  border: 2px solid #ff9800;
  color: white;
}

h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

h4 {
  font-size: 18px;
  margin: 5px 0;
}
