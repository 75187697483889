@font-face {
  font-family: "Century Gothic";
  src: url("./fonts/centurygothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Century Gothic", sans-serif;
  max-width: 1600px; /* Adjust this based on your design */
  margin: 0 auto; /* Center content */
}

a:link {
  text-decoration: none;
}
