.gallery-container {
  display: flex;
  height: 100vh;
}

.art-styles-column {
  width: 20%;
  padding: 10px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.photos-grid-column {
  width: 80%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-top: 10px;
}

.grid-item {
  width: 100%;
  height: 200px;
  /* Adjust as needed for better display */
  background-color: #f0f0f0;
  /* Default color for empty slots */
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul {
  list-style: none;
  padding: 0;
}

.style-item {
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  color: black;
  transition: background-color 0.3s;
}

.style-item:hover {
  color: white;
  background-color: #f4bf4f;
}

.style-item.active {
  background-color: #ffad00;
  color: white;
}

.pagination-controls {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

button {
  height: 35px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #ffad00;
  border-radius: 5px;
  border: none;
  color: white;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-top: 10px;
  position: relative;
}
