.product-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
  background-color: #fff;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-description {
  font-size: 1rem;
  color: #333;
}

.product-price {
  font-size: 1.2rem;
  color: #ffa500;
}

.detail-button {
  background-color: #ffa500;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
}
