/* Reviews Component Styles */
.reviews-container {
  text-align: center;
  padding: 20px;
  background-color: #f6f2ea;
  margin-top: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 5px;
  height: 260px;
}

.reviews-title {
  font-size: 24px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .reviews-slider-wrapper .slick-slide {
    padding: 0;
    padding-left: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

/* Target only the reviews slider */
.reviews-slider-wrapper .slick-slide {
  padding-left: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
}

.reviews-slider-wrapper .slick-list {
  height: 180px;
}

.review-card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100px; /* Set a fixed width for the review card */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: auto;
  min-height: 170px;
}

.review-card h3 {
  font-size: 12px;
  margin-bottom: 5px;
}

.review-card p {
  font-size: 10px;
  color: #555;
}

.stars {
  color: #f39c12;
  margin-bottom: 5px;
}
