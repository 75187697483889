/* Upper Navbar */
.upper-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: orange;
  height: 50px;
  box-shadow: 0px 1px 5px gray;
  padding: 0 1rem;
}

/* Align Left and Right Sections */
.left-section,
.right-section {
  display: flex;
  align-items: center;
}

/* Styling for Navbar Links */
.upper-navbar a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.upper-navbar a:hover {
  color: #ffd966;
}

/* Adjust spacing for icons with text, like Cart and User Profile */
.upper-navbar .icons a {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

/* Main Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 1100px) {
  .mobile-drawer-icon {
    display: none !important;
  }
}

@media screen and (max-width: 1100px) {
  .navbar {
    display: none !important;
  }

  .left-section > *:nth-child(n + 3) {
    display: none;
  }
}

button .mobile-drawer-icon {
  display: none;
  color: white !important;
}

/* Logo Styling */
.logo {
  display: flex;
  align-items: center;
}

.left-img {
  height: 170px;
}

.right-img {
  width: 220px;
  margin-left: 5px;
  margin-top: 4px;
}

/* Nav Links Styling */
.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 100%;
  border: 1px solid black;
  border-width: 2px 0px 2px 0px;
  color: black;
  margin-bottom: 18px;
}

.nav-links a {
  color: #000;
  padding: 10px 20px;
  font-weight: bold;
  text-decoration: none;
}

.nav-links a:hover {
  color: #ff9900;
}

.nav-links a.active {
  background-color: #ffad00;
  border-radius: 5px;
}

.nav-links .orange-border {
  border-radius: 5px;
}

.nav-links .blue-border {
  border-radius: 5px;
}

.nav-links a.active:hover {
  color: white;
}

/* Responsive Adjustments */
@media screen and (max-width: 1400px) {
  .left-img {
    height: 100px;
  }

  .nav-links a {
    padding: 10px 3px;
  }
}

@media screen and (max-width: 768px) {
  .upper-navbar a,
  .nav-links a {
    font-size: 0.9rem;
  }
}

.drawer-content {
  padding: 20px;
}

@media screen and (max-width: 1200px) {
  .hide-on-small {
    display: none !important;
  }
}
