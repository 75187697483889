.other-container {
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 20px;
  gap: 20px;
  align-items: start;
}

.other-column1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.other-logo {
  max-width: 70%;
  height: auto;
  max-height: 500px;
  margin-bottom: 20px;
}

.other-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.other-button {
  padding: 10px 20px;
  font-size: 18px;
  height: 45px;
  background-color: #ffad00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.other-button:hover {
  background-color: #e29a00;
}

.other-column2 {
  padding-right: 100px;
  font-size: 16px;
  line-height: 1.6;
}

.other-content {
  color: #000;
}

.other-content h3 {
  font-size: 20px;
  margin: 10px 0;
}

.other-content p,
.other-content ul {
  margin-bottom: 10px;
}

.other-content .contact-orange {
  color: #ffad00;
  font-weight: bold;
}

.other-button.active {
  color: black;
  border: 1px solid black;
}
