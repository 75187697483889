.orders-history-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.order-card {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.order-summary {
  cursor: pointer;
}

.toggle-details-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.order-details {
  margin-top: 20px;
}

.order-item-image {
  width: 100px;
  height: auto;
  border-radius: 4px;
  margin-top: 10px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}
